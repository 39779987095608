<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveBusinessEntity)" id="user-form">
                    <h5> Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-left">
                            <b-form-group label="Tipo de persona">
                                <b-form-radio-group v-model="businessEntity.type" :options="businessEntityTypes"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group label="Tipo">
                                <b-form-checkbox v-model="businessEntity.isCustomer" inline>
                                    Cliente
                                </b-form-checkbox>
                                <b-form-checkbox v-model="businessEntity.isSupplier" inline>
                                    Proveedor
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="businessEntity.type === 'MORAL'">
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Razón social" invalid-feedback="Ingresa la razón social">
                                    <b-form-input v-model="businessEntity.legalName" autocomplete="false" placeholder="Razón social"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group label="Régimen capital">
                                <b-form-input v-model="businessEntity.legalEntityType" autocomplete="false" placeholder="Régimen capital"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="RFC">
                                <b-form-input v-model="businessEntity.rfc" autocomplete="false" placeholder="RFC"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="businessEntity.type === 'FISICA'">
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Nombre(s)" invalid-feedback="Ingresa el/los nombre(s)">
                                    <b-form-input v-model="businessEntity.name" autocomplete="false" placeholder="Nombre(s)"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Apellido paterno">
                                <b-form-input v-model="businessEntity.firstLastName" autocomplete="false" placeholder="Apellido paterno"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left" key="businessEntitySecondLastName">
                            <b-form-group label="Apellido materno">
                                <b-form-input v-model="businessEntity.secondLastName" autocomplete="false" placeholder="Apellido materno"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="RFC">
                                <b-form-input v-model="businessEntity.rfc" autocomplete="false" placeholder="RFC"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6" class="text-left">
                            <b-form-group label="Nombre comercial">
                                <b-form-input v-model="businessEntity.commercialName" autocomplete="false" placeholder="Nombre comercial"
                                              @input="setManuallyEditedFlag"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="businessEntity.type === 'MORAL'">
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Nombre(s) del contacto">
                                <b-form-input v-model="businessEntity.name" autocomplete="false" placeholder="Nombre(s) del contacto"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Apellido paterno del contacto">
                                <b-form-input v-model="businessEntity.firstLastName" autocomplete="false" placeholder="Apellido paterno"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left" key="businessEntitySecondLastName">
                            <b-form-group label="Apellido materno del contacto">
                                <b-form-input v-model="businessEntity.secondLastName" autocomplete="false" placeholder="Apellido materno"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Teléfono 1">
                                <b-form-input v-model="businessEntity.phone1" autocomplete="false" placeholder="Teléfono 1"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Teléfono 2">
                                <b-form-input v-model="businessEntity.phone2" autocomplete="false" placeholder="Teléfono 2"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <ValidationProvider rules="email" v-slot="{ errors }">
                                <b-form-group label="Email 1" invalid-feedback="Ingresa un email válido">
                                    <b-form-input v-model="businessEntity.email1" autocomplete="false" placeholder="Email 1"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <ValidationProvider rules="email" v-slot="{ errors }">
                                <b-form-group label="Email 2" invalid-feedback="Ingresa un email válido">
                                    <b-form-input v-model="businessEntity.email2" autocomplete="false" placeholder="Email 2"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerIndex' : 'SupplierIndex') }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerShow' : 'SupplierShow'), params: { id: businessEntity.id }}" v-if="businessEntity.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { businessEntityUrl } from '@routes';
import * as constants from '@constants';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('email', {
    ...email,
    message: 'Ingresa un email válido'
});

export default {
    data() {
        return {
            businessEntity: {
                id: 0,
                name: '',
                firstLastName: '',
                secondLastName: '',
                legalName: '',
                commercialName: '',
                legalEntityType: '',
                rfc: '',
                phone1: '',
                phone2: '',
                email1: '',
                email2: '',
                type: 'FISICA',
                isCustomer: true,
                isSupplier: false
            },
            businessEntityTypes: [
                { text: 'Persona física', value: 'FISICA' },
                { text: 'Persona moral', value: 'MORAL' }
            ],
            toastTitle: '',
            breadcrumb: {},
            action: 'Nuevo',
            isCommercialNameManuallyEdited: false,
            isLoading: false
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        if (this.$route.meta.module == 'supplier') {
            this.businessEntity.isCustomer = false;
            this.businessEntity.isSupplier = true;
        }

        this.loadBreadcrumb(this.$route.meta.module);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(businessEntityUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.businessEntity = JSON.parse(JSON.stringify(response.data.businessEntity));
                    this.isCommercialNameManuallyEdited = true;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveBusinessEntity() {
            this.isLoading = true;

            this.axios.post(businessEntityUrl(), this.businessEntity).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.$router.push({ name: (this.$route.meta.module === 'customer' ? 'CustomerShow' : 'SupplierShow'), params: { id: response.data.id }});
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        loadBreadcrumb(module) {
            if (module === 'supplier') {
                this.breadcrumb = {
                    title: 'Proveedores',
                    path: [
                        {
                            name: 'SupplierIndex',
                            text: 'Proveedores'
                        }
                    ]
                };
                this.toastTitle = 'Proveedores';
            } else if (module === 'customer') {
                this.breadcrumb = {
                    title: 'Clientes',
                    path: [
                        {
                            name: 'CustomerIndex',
                            text: 'Clientes'
                        }
                    ]
                };
                this.toastTitle = 'Clientes';
            }

            this.breadcrumb.path.push({
                name: this.$route.name,
                text: `${this.action}`
            });

            this.$emit('update-breadcrumb', this.breadcrumb);
        },
        setCommercialName(type) {
            if (this.isCommercialNameManuallyEdited) {
                return;
            }

            if (type === 'FISICA') {
                this.businessEntity.commercialName = `${this.businessEntity.name} ${this.businessEntity.firstLastName} ${this.businessEntity.secondLastName}`.trim();
            } else {
                this.businessEntity.commercialName = this.businessEntity.legalName;
            }
        }, setManuallyEditedFlag() {
            this.isCommercialNameManuallyEdited = this.businessEntity.commercialName !== '';
        }
    },
    computed: {
    },
    watch: {
        'businessEntity.name'() {
            this.setCommercialName(this.businessEntity.type);
        },
        'businessEntity.firstLastName'() {
            this.setCommercialName(this.businessEntity.type);
        },
        'businessEntity.secondLastName'() {
            this.setCommercialName(this.businessEntity.type);
        },
        'businessEntity.legalName'() {
            this.setCommercialName(this.businessEntity.type);
        },
        'businessEntity.commercialName'() {
            if (this.businessEntity.commercialName === '') {
                this.isCommercialNameManuallyEdited = false;
            }
        }
    }
};
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: transform 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        transform: translateX(-120%);
    }

    .fade-enter-to,
    .fade-leave {
        transform: translateX(0);
    }
</style>